import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TermineMargin from "../../components/termine-margin"
import ContentHeader from "../../components/unsereschule/contentHeader"
import Submenu from "../../components/unsereschule/submenu"

import { graphql } from "gatsby"

export const query = graphql`
  {
    allSanitySiteSettings {
      nodes {
        motto
      }
    }
    allSanityKollegium {
      group(field: _id) {
        nodes {
          kollegen {
            firstname
            function
            lastname
            email
          }
        }
      }
    }
  }
`

const Kollegium = ({data}) => (
  <Layout>
    <SEO title="Ansprechpartner"/>

    <ContentHeader headline="UNSERE SCHULE" subline={data.allSanitySiteSettings.nodes[0].motto}/>
    <section id="content_2_schule">

      <Submenu/>

      <article id="con_2_rechts_schule">
        <h3 className="small_sub">
          <span style={{ color: "#007C34" }}>LEHRERKOLLEGIUM UND MITARBEITER</span>
        </h3>
        <p className="text">Im Schuljahr 2022 / 2023 werden bei uns 260 Sch&uuml;lerinnen und Sch&uuml;ler von 16
          Lehrerinnen und Lehrern, einer Sozialpädagogischen Fachkraft und einer Lehramtsanw&auml;rterin
          unterrichtet. Außerdem ist eine Sozialarbeiterin tageweise im Einsatz.
        </p>
      </article>

      <article id="con_2_rechts_lightbox">
        <div className="container-kopf">
          <table width='638' border='1' cellPadding='5' cellSpacing='0' className='text'>
            <thead>
              <tr>
                <td width="130" bgcolor="#efefef">Vorname</td>
                <td width="130" bgcolor="#efefef">Nachname</td>
                <td width="230" bgcolor="#efefef">E-Mail</td>
                <td width="120" bgcolor="#efefef">Beschreibung</td>
              </tr>
            </thead>
            <tbody>
            {data.allSanityKollegium.group[0].nodes[0].kollegen.map((item) =>
              <tr>
                <td>{item.firstname}</td>
                <td>{item.lastname}</td>
                <td><a href={'mailto:' + item.email}>{item.email}</a></td>
                <td>{item.function}</td>
              </tr>
            )}
            </tbody>
          </table>
        </div>


      </article>
      <TermineMargin/>
    </section>
  </Layout>
)

export default Kollegium
